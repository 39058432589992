



/**
 * Init Left menu
 */

var _body = document.body;

function LeftMenu() {
	"use strict";

	$('.push-menu').on('click touch', function (e) {
		e.preventDefault();



		setTimeout(function () {

			var body_class = 'c-left-menu--opened';
			var element_class = 'c-left-menu--push';

			_body.classList.add(body_class);
			_body.classList.add(element_class);

			ShowMask();
			MenuClose(body_class, element_class);

		}, 100);

	});
}








function RightMenu() {
	"use strict";

	$('body').on('click touch','.init-right-menu', function (e) {
		e.preventDefault();

		var cart_nb = $(this).data('cartitems');
		let body_class = 'c-right-menu--opened';
		let element_class = 'c-right-menu--push';

		if (cart_nb > 0) {

			setTimeout(function () {

				_body.classList.add(body_class);
				_body.classList.add(element_class);

				ShowMask();

				new LazyLoad({elements_selector:".lazyload"}).loadAll();

				MenuClose(body_class, element_class);


			}, 100);

		} else {
			window.location = $(this).data('cart-link');
		}

	});
}




/**
 * Close Top, Left, Right menu
 */

function MenuClose($body, $element) {
	"use strict";

	$('.bg-mask, .close-menu').on('click touch', function () {

		if (_body.classList.contains($element) && _body.classList.contains($body) ) {

			_body.classList.remove($element);
			_body.classList.remove($body);

			HideMask();

			setTimeout(function () {
				$('.c-right-menu__cart').show();
				$('.c-right-login').hide();
			}, 600);



		}

		return false;

	});

}


/**
 * Hide Mask
 */

function HideMask() {
	"use strict";

	$('.bg-mask').fadeOut(250, function () {
		$('.bg-mask').remove();
	});

}


/**
 * Init Mask Background
 */

function ShowMask() {
	"use strict";

	var mask = '<div class="bg-mask">';
	var mask_element = '.bg-mask';

	if($('body').find('.bg-mask').length === 0) {
		$(mask).hide().appendTo('body');
		$('body').find(mask_element).height($(document).height()).fadeIn(250);
	}

	$(window).resize(function () {
		$('body').find(mask_element).height($(document).height());
	});
}


$(document).ready(function () {
	"use strict";
	LeftMenu();
	RightMenu();

	$('.grade-stars').rating();

});







var btn_back = document.querySelectorAll('.btn-back');


if(btn_back.length > 0) {
	btn_back[0].addEventListener('click', function(){
		window.history.back();
	});
}


// console.log(prestashop);

prestashop.on('updatedCart', function(event) {

	if (typeof products_count !== 'undefined') {

		if (products_count < 1) {
			window.location.reload(true);
		}


	}

});





function initSearchMobile() {
	"use strict";

	var searchElement = $('.b-search');
	var menuElement = $('.b-megamenu');
	var body = $('body');

	$('.js-init-search').on('click', function (e) {
		e.preventDefault();


		if (body.hasClass('search-opened')) {

			body.removeClass('search-opened');


			$(this).parents( ".header-top" ).find('.js-init-search i').toggleClass('icon-close icon-search');

			searchElement.hide(1,function(){
				menuElement.show();
				// searchElement.find('i').toggleClass('icon-close icon-search');
			});


			$('.js-search-close').on('click', function () {
				body.removeClass('search-opened');

				searchElement.hide(1,function(){
					searchElement.find('input[type="text"]').val('');
					menuElement.show();
				});
			});

		} else {

			body.toggleClass('search-opened');

			$(this).parents( ".header-top" ).find('.js-init-search i').toggleClass('icon-search icon-close');

			menuElement.hide(1,function(){
				searchElement.show();
				searchElement.find('i').toggleClass('icon-search icon-close');
				searchElement.find('input[type="text"]').focus();
			});




		}



	});

}


initSearchMobile();




$(window).scroll(function() {

	if($('body').hasClass('page-order')){
		return;
	}

	var position;

	if ($(window).width() > 992) {
		position = 650;
	} else {
		position = 1;
	}

	if ($(this).scrollTop() > position){
		$('.header').addClass("sticky");
	}
	else{
		$('.header').removeClass("sticky");
	}
});




function initMenu() {
	"use strict";




	var body_class = 'c-left-menu--opened';
	var element_class = 'c-left-menu--push';

	$('.init-menu').on('click touch', function (e) {

		e.preventDefault();

		////delete

		if (_body.classList.contains(element_class) && _body.classList.contains(body_class) ) {

			_body.classList.remove(element_class);
			_body.classList.remove(body_class);

			HideMask();
			// MenuClose(body_class, element_class);

			$(this).removeClass('close-menu');


		} else {

			setTimeout(function () {

				_body.classList.add(body_class);
				_body.classList.add(element_class);

				ShowMask();



				// MenuClose(body_class, element_class);

				$(this).addClass('close-menu');


			}, 1);

		}



	});


	///hover {
	$('.b-mainmenu.position-absolute').on('mouseleave', function(event){
		if (_body.classList.contains(element_class) && _body.classList.contains(body_class) ) {

			_body.classList.remove(element_class);
			_body.classList.remove(body_class);

			HideMask();
			MenuClose(body_class, element_class);
		}
	});




}

initMenu();


$('.b-seo__btn').click(function() {
	$('.b-seo').toggleClass('b-seo--masked b-seo--open');
	// $(this).fadeOut(250);
});

$(document).on('scroll',function()
{

	const wrap_menu = $('#_desktop_megamenu .menu-scroll');

	$('.homepage-section').each(function() {

		if ( $(this).offset().top < window.pageYOffset + 220 &&
			$(this).offset().top + $(this).height() > window.pageYOffset + 220
		) {
			const menu_item = $(this).data('anchor');

			(function () {
				if(menu_item !== '') {
					wrap_menu.find('a').removeClass('active'); // Select clicked link brothers.
					$('#_desktop_megamenu .menu-scroll.' +menu_item).find('a').addClass('active');
				}
			})();

		}
	});
});

$(document).on("click touchstart", ".dropdown-select__list .dropdown-select__item", function (e) {

	$(this).parent().parent().prev('.dropdown-select').find('.select-title').text($(this).text());
	$(this).parent().prev('.dropdown-select__list').removeClass('show');
	$(this).parent().prev('.dropdown-select__list li').removeClass('active');
});

function initHashClick() {
	"use strict";

	var scrollToAmount;

	if( $('body').hasClass('scroll-page') ){

		if($('body').hasClass('page-product')) {
			$('.pos-menu-horizontal .menu-item:first-child a').addClass('active');
		}

		$(document).on("click touchstart", "#_desktop_megamenu .menu-scroll a", function (e) {

			e.preventDefault();

			var $this = $(this);
			var hash_position = $this.attr("href");
			var hash = hash_position.split('#')[1];

			var anchor = $('[data-anchor="' + hash + '"]');

			if (anchor.length > 0) {

				scrollToAmount = anchor.offset().top - 150 - 40;

				$('html, body').stop().animate({
					scrollTop: Math.round(scrollToAmount)

				}, 1000, 'swing', function () {
					changeActiveState($this);
				});

				//
				// if (history.pushState) {
				// 	history.pushState(null, null, '#' + hash);
				// }

				return false;

			} else {

				changeActiveState($this);

			}


		});





		setTimeout(function(){
			checkAnchorOnLoad();
		},500);







	}

}
function checkAnchorOnLoad(){
	"use strict";

	var hash = window.location.hash.split('#')[1];

	if(hash !== "") {
		var anchor = $('[data-anchor="' + hash + '"]');
	}

	if( anchor.length > 0 && hash !== "description"){

		var scrollToAmount = anchor.offset().top - 150 - 20;

		$('html, body').stop().animate({
			scrollTop: Math.round(scrollToAmount)

		}, 500, 'swing', function () {
			changeActiveState(anchor);
		});

	}

}
function changeActiveState(id) {
	"use strict";

	$("#_desktop_megamenu .menu-scroll").each(function () {

		$(this).find('a').removeClass('active');
		id.addClass('active');
	});

}

initHashClick();

var actionURL = prestashop.urls.pages.cart;

/* Ajax Add to cart */
function ajaxAddToCart() {
	if (prestashop.configuration.is_catalog === false) {

		$('body').on('click', '.js-ajax-add-to-cart', function (event) {
			event.preventDefault();

			var $btn = $(this);
			$btn.removeClass('added').addClass('disabled');
			var img = $btn.find('img');
			var preloader = $btn.find('.add-to-cart-loading');

			img.hide();
			preloader.addClass('show');

			var query = 'id_product=' + $btn.data('id-product') + '&add=1&action=update&token=' + prestashop.static_token;
			query = query + '&qty=1';

			$.post(actionURL, query, null, 'json').then(function (resp) {

				prestashop.emit('updateCart', {
					reason: {
						idProduct: resp.id_product,
						idProductAttribute: resp.id_product_attribute,
						linkAction: 'add-to-cart'
					},
					resp
				});

				$btn.addClass('added');
				$btn.removeClass('disabled');
				preloader.removeClass('show');
				img.show();
				$btn.addClass('quick-buy__btn-active');

				if (resp.hasError) {
					$('.js-modal-message-text').text(resp.errors[0]);
					$('.js-modal-message').modal('show');
				}
			}).fail(function (resp) {
				prestashop.emit('handleError', { eventType: 'addProductToCart', resp: resp });
			});

			return false;
		});
	}
}



function ajaxAddToCartSecond() {
	if (prestashop.configuration.is_catalog === false) {

		$('body').on('click', '.ajax_add_to_cart_button', function (event) {
			event.preventDefault();

			var $btn = $(this);

			var query = 'id_product=' + $btn.data('id-product') + '&add=1&action=update&token=' + prestashop.static_token;
			query = query + '&qty=1';

			$.post(actionURL, query, null, 'json').then(function (resp) {
				prestashop.emit('updateCart', {
					reason: {
						idProduct: resp.id_product,
						idProductAttribute: resp.id_product_attribute,
						linkAction: 'add-to-cart'
					},
					resp
				});

				$btn.removeClass('disabled');
				$btn.find('.add-to-cart-loading').remove();
				$btn.addClass('quick-buy__btn-active');

				if (resp.hasError) {
					$('.js-modal-message-text').text(resp.errors[0]);
					$('.js-modal-message').modal('show');
				}

			}).fail(function (resp) {
				prestashop.emit('handleError', { eventType: 'addProductToCart', resp: resp });
			});

			return false;
		});
	}
}


function ajaxInitLogin() {


	$('body').on('click', '.m-prev', function (event) {
		event.preventDefault();

		$('.c-right-menu__cart').show();
		$('.c-right-login').hide();

		return false;
	});


	$('body').on('click', '.open-login', function (e) {
		e.preventDefault();

		let body_class = 'c-right-menu--opened';
		let element_class = 'c-right-menu--push';

		let data = $(this).data('action');
		let isLogin = false;

		if(typeof data !== 'undefined') {
			isLogin = true;
		}


		if (_body.classList.contains(element_class) && _body.classList.contains(body_class) ) {
			RightMenuShowLogin(isLogin);
		} else {
			setTimeout(function () {

				_body.classList.add(body_class);
				_body.classList.add(element_class);

				ShowMask();
				MenuClose(body_class, element_class);

				RightMenuShowLogin(isLogin);

			}, 100);
		}
		return false;
	});

}


function RightMenuShowLogin(isLogin) {
	$('.c-right-menu__cart').hide();
	$('.c-right-login').show();
	$('.register-guest').show();

	if(isLogin) {
		$('.register-guest').hide();
	}
}

$('.login-form').keypress(function(event){
	var keycode = (event.keyCode ? event.keyCode : event.which);
	if(keycode === 13){
		signIn();
		return false;
	}
});


ajaxAddToCart();
ajaxAddToCartSecond();

ajaxInitLogin();


/* Grid - List - Table */
function sortListingProducts() {
	var elm = '.btn-sort';
	var storage =  window.localStorage || window.sessionStorage;

	$('body').on('click', elm, function (event) {
		event.preventDefault();

		$('.sort-panel').toggleClass('sort-panel--show');

		// var view = $(this).data('sort');
		// $(elm).removeClass('selected');
		// $(this).addClass('selected');
		// $('.js-product-list-view').removeClass('grid list table-view').addClass(view);

		// try {
		// 	storage.setItem('productListSort', view);
		// }
		// catch (error) {
		// 	console.log('Can not cache the product list view');
		// }



	});

	// if (storage.productListView) {
	// 	$(elm + '[data-sort="' + storage.productListSort + '"]').trigger('click');
	// } else {
	// 	$(elm + '.selected').trigger('click');
	// }
}

sortListingProducts();




////// USER
$('body').on('click', '[data-link-action=x-sign-in]', function () {
	signIn();
	return false;
});

function signIn() {

	var checkPostCodeUrl = $('.login-form').data('url');

	$('#errors-login-form').slideUp();
	$('[data-link-action=x-sign-in]').prop('disabled', true).css('cursor', 'wait');

	let back = $('.register-guest:visible input[name="back"]').val();

	console.log(back);

	$.ajax({
		type: 'POST',
		cache: false,
		dataType: "json",
		url: checkPostCodeUrl,
		data:
			"&action=signIn&ajax=true&"
			+ $('.login-form').serialize()
			+ "&token=" + $('[name=token1]').val()
		,
		success: function (jsonData) {
			$('[data-link-action=x-sign-in]').prop('disabled', false).css('cursor', 'pointer');

			if (jsonData.hasErrors) {
				var errMsg = formatErrors(jsonData.errors);
				$('#errors-login-form').html(errMsg).slideDown();

			} else {
				signedInUpdateForm(back);
			}

		}
	});
}
function signedInUpdateForm(back) {
	$('[data-link-action=x-sign-in], .forgot-password').hide();
	$('.successful-login.hidden').show();

	if(back) {
		window.location.href = back;
	} else {
		window.location.reload();
	}

}
function formatErrors(errors, tag) {
	if ('undefined' === typeof tag) {
		tag = 'div';
	}
	var errMsg = "";
	$.each(errors, function (index, value) {
		if ("" !== jQuery.trim(value)) {
			errMsg += "<" + tag + ">";
			if ("" !== jQuery.trim(index) && isNaN(index)) {
				errMsg += index + ': ';
			}
			errMsg += value + "</" + tag + ">\n";
		}
	});
	return errMsg;
}

$(document).ready(function() {

	var $selected = $(this);

	$('select').select2({
		dropdownAutoWidth: true,
		minimumResultsForSearch: -1,
		theme: 'bootstrap4',
	}).on('select2:open', function(e){
		$selected.find('.select2-dropdown').addClass('animated-fast fadeIn');
	}).on('select2:closing', function(e){
		$selected.find('.select2-dropdown').removeClass('animated-fast fadeOut');
	});

});

"use strict";


var topBtn = document.querySelector(".top-btn");
topBtn.onclick = function () {
	return window.scrollTo({ top: 0, behavior: "smooth" });
};
window.onscroll = function () {
	return window.scrollY > 500 ? topBtn.style.opacity = 1 : topBtn.style.opacity = 0;
};
