// var LoadVideo = function(player_id){
//
// 	console.log(player_id);
//
// 	var Program = {
//
// 		Init: function(){
// 			this.NewPlayer();
// 			this.EventHandler();
// 		},
//
// 		NewPlayer: function(){
// 			var _this = this;
// 			this.Player = new YT.Player(player_id, {});
// 			_this.Player.$element = $('#' + player_id);
// 		},
//
// 		Play: function(){
// 			// console.log(this.Player.IsReady);
// 			if( this.Player.getPlayerState() === 1 ) return;
// 			this.Player.playVideo();
// 		},
//
// 		Pause: function(){
// 			if( this.Player.getPlayerState() === 2 ) return;
// 			this.Player.pauseVideo();
// 		},
//
// 		ScrollControl: function(){
// 			if( Utils.IsElementInViewport(this.Player.$element[0]) ) this.Play();
// 			else this.Pause();
// 		},
//
// 		EventHandler: function(){
// 			var _this = this;
//
// 			$('#' + player_id).on('mouseover', function(){
// 			// // 	// _this.ScrollControl();
// 			// //
// 			// // 	_this.Play();
// 			//
// 				console.log(1);
// 			// //
// 			}).on('mouseout',function(){
// 			// // 	// player3.stopVideo();
// 			// // 	_this.Pause();
// 				console.log(2);
// 			});
//
//
//
// 			// object.addEventListener("mouseover", myScript);
//
// 			console.log(_this);
//
//
// 			// this.Player.playVideo();
// 			// _this.Play();
//
//
// 			// $('.tab-link').on('click', function(){
// 			// 	var $target = $('#' + $(this).data().tab);
// 			// 	if( !!$('iframe', $target).length && $('iframe', $target).attr('id') == _this.Player.$element.attr('id') ){
// 			// 		_this.Play();
// 				// }
// 			// });
//
// 		},
// 	};
//
// 	var Utils = {
//
// 		/** @author http://stackoverflow.com/a/7557433/1684970 */
// 		IsElementInViewport: function(el){
// 			if (typeof jQuery === "function" && el instanceof jQuery) el = el[0];
// 			var rect = el.getBoundingClientRect();
// 			return (
// 				rect.top >= 0 &&
// 				rect.left >= 0 &&
// 				rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
// 				rect.right <= (window.innerWidth || document.documentElement.clientWidth)
// 			);
// 		}
//
// 	};
//
// 	Program.Init();
//
// };










$(document).ready(function () {
	"use strict";


	// $('.preview-video').on('mouseover',function(e){
	// 	// LoadVideo($(this).find('iframe').attr('id'), e.currentTarget);
	//
	// 	// players[currentHoveredElement.id].playVideo();
	//
	// 	// var currentIframeID11 = videos[i].id; // we get the iframe ID
	// 	// players[currentIframeID11] =
	// 		new YT.Player($(this).find('iframe').attr('id'), {}); // we stock in the array the instance
	//
	// 	// console.log(e.currentTarget);
	//
	//
	// }).on('mouseout',function(){
	// 	// player3.stopVideo();
	// 	// console.log('pause video');
	// });


	// $('ul.tabs li').on('click', function(){
	// 	var tab_id = $(this).attr('data-tab');
	//
	// 	$('ul.tabs li').removeClass('current');
	// 	$('.tab-content').removeClass('current');
	//
	// 	LoadVideo('playerA');
	// 	LoadVideo('playerB');
	//
	//
	// 	$(this).addClass('current');
	// 	$('#' + tab_id).addClass('current');
	// });

});



////
// $(document).ready(function () {
document.addEventListener("DOMContentLoaded", function(event) {

	var player;

	var $ = function (id) {
		return document.getElementById(id);
	}
	var $$ = function (tagname) {
		return document.getElementsByTagName(tagname);
	}

	function onYouTubeIframeAPIReady() {


		var videos = document.getElementsByTagName("iframe"); // the iframes elements


		// var videos = document.querySelectorAll(".preview-video iframe"), // the iframes elements
		var players = [], // an array where we stock each videos youtube instances class
		playingID = null; // stock the current playing video

		for (var i = 0; i < videos.length; i++) // for each iframes
		{

			var currentIframeID = videos[i].id; // we get the iframe ID
			players[currentIframeID] = new YT.Player(currentIframeID, {}); // we stock in the array the instance

			videos[i].onmouseover = function (e) { // assigning a callback for this event

				console.log('in');

				var currentHoveredElement = e.target;

				// var currentIframeID = this.id; // we get the iframe ID
				// players[currentIframeID] = new YT.Player(currentIframeID, {}); // we stock in the array the instance

				players[currentHoveredElement.id].playVideo();
				// playingID = currentHoveredElement.id;
			};


			videos[i].onmouseout = function (e) { // assigning a callback for this event

				console.log('out');

				var currentHoveredElement = e.target;
				playingID = currentHoveredElement.id;

				if (playingID){
					players[playingID].pauseVideo();
				}

			};


		}

	}

	// onYouTubeIframeAPIReady();

});


$(document).ready(function(){

	// $('a.ml').click(function(){
	// // 	loadAndPlayVideo( $(this).find('img').attr('id').replace(/thumb_/,'') );
	//
	//
	//
	// 	return false;
	// })

	var timer,
		count = 0,
		cycle = function(el){
			var s = el.attr('src'),
				root = s.substring( 0, s.lastIndexOf('/') + 1 );
			count = (count+1)%4;
			el.attr('src', root + ((count==0) ? 'hqdefault' : 'hq'+count) + '.jpg');
		};

	$('.mb').hover(function(){
		var $this = $(this).find('img.video-thumb');
		cycle($this);
		timer = setInterval(function(){ cycle($this); }, 500);
	}, function(){
		clearInterval(timer);
	});

});
//
